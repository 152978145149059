import React from "react";

import './index.css'

const Footer = () => (
  <div className="footer">
    <p>An open source initiative by <a href="https://www.blockwall.capital/">Blockwall Capital</a></p>
  </div>
);

export default Footer;
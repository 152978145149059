import React from 'react';
import { Typography } from '@mui/material';


const Home = () => {
  return (
    <div>
      {/* <Typography component='div' variant='h3' align='center'>
        &nbsp;
      </Typography> */}
      {/* <Typography style={{color:"#ff0000"}} sx={{px: 2}} component='div' variant='h3' align='center'>
        The open database for discovering new  venture capital fund managers!
      </Typography> */}
      <Typography component='div' variant='h8' align='center'>
        &nbsp;
      </Typography>

      <iframe title="opengp1" class="airtable-embed" src="https://airtable.com/embed/shrEWzDLC6kbnqrI2?backgroundColor=red&layout=card&viewControls=on" frameborder="0" onmousewheel="" width="90%" height="650" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>
    </div>
  )
}

export default Home;
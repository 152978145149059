import { AppBar, Toolbar, IconButton, Typography, Stack, Button } from "@mui/material"
import AcUnitIcon from '@mui/icons-material/AcUnit';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import React, { useEffect, useState } from "react";

import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyBkmcPqvFAAp1fLdtkGbYUWRom3ilUojb4",
  authDomain: "opengp-b767c.firebaseapp.com",
  projectId: "opengp-b767c",
  storageBucket: "opengp-b767c.appspot.com",
  messagingSenderId: "1001637570948",
  appId: "1:1001637570948:web:af3dbd3cfddf8290b395d1",
  measurementId: "G-ZPQPW82C3H"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp)

const logout = () => {
  signOut(auth)
};

const Navbar = () => {
  const [user, loading, error] = useAuthState(auth);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton size='large' edge='start' color='inherit' aria-label='logo'component={Link} to='/'>
          <AcUnitIcon />
        </IconButton>
        <Typography color="common.white" component={Link} to="/"variant='h6' align='left' sx={{ flexGrow: 1, textDecoration: "none"}}>
          OpenGP
        </Typography>
        <Stack direction = 'row' spacing={2}>
        <IconButton style={{ color: 'white' }} onClick={() => window.open('https://ctt.ac/hbDmL', '_blank')} >
          <TwitterIcon />
        </IconButton>
          <Button color='inherit' component={Link} to="/contact">Contact</Button>
          <Button color='inherit' component={Link} to="/fund">Add New Fund</Button>

          <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={{}}>{user.email}</MenuItem>
                <MenuItem onClick={logout}>Log out</MenuItem>
              </Menu>

        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';


import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { initializeApp } from "firebase/app";


import Home from '../Home/Home';
import Navbar from '../Navbar/Navbar';
import Contact from '../Contact/Contact';
import Fund from '../Fund/Fund';

const firebaseConfig = {
    apiKey: "AIzaSyBkmcPqvFAAp1fLdtkGbYUWRom3ilUojb4",
    authDomain: "opengp-b767c.firebaseapp.com",
    projectId: "opengp-b767c",
    storageBucket: "opengp-b767c.appspot.com",
    messagingSenderId: "1001637570948",
    appId: "1:1001637570948:web:af3dbd3cfddf8290b395d1",
    measurementId: "G-ZPQPW82C3H"
  };
  
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp)


const SignIn = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  
  const [user, loading, error] = useAuthState(auth);

  const login = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user.email)
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode)
        console.log(errorMessage)
        alert(errorCode)

      });
  };

//   const logout = () => {
//   signOut(auth)
// };

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    return (
      <div>
        <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="fund" element={<Fund />} />

      </Routes>

      </div>
    )
  }

  return (
  <>

  <Container component="main" maxWidth="xs">
  <CssBaseline />
    <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
    <Typography component="h1" variant="h5"> Sign in </Typography>

  <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
        </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={login}
              >
                Sign In
              </Button>
    </Box>
  </Container>

  </>
 
 )};

export default SignIn;

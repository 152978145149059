import React from 'react';
import { Routes, Route } from "react-router-dom";
import "./App.css";

import { CssBaseline } from '@mui/material';

import Footer from './Footer/Footer'
//import DataGrid from './Table/DataGrid';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Navbar from './Navbar/Navbar';
import Fund from './Fund/Fund';
import SignIn from './SignIn/SigIn'

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <SignIn />
      {/* <Navbar /> */}

{/*       
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="fund" element={<Fund />} />

      </Routes> */}
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'

const Fund = () => {
  return (
    <div>

    
      <>
      <main>
      <iframe title='fund' class="airtable-embed" src="https://airtable.com/embed/shr5GCJ68Bpx36ihE?backgroundColor=red" frameborder="0" onmousewheel="" width="100%" height="533" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>
      </main>

    </>
    </div>
  )
}

export default Fund
import React from 'react';

const Contact = () => {
  return (
    <div>

    
      <>
      <main>
      <iframe title='contact' class="airtable-embed" src="https://airtable.com/embed/shroGh07P17J0yo3t?backgroundColor=blue" frameborder="0" onmousewheel="" width="100%" height="733" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>
      </main>

    </>
    </div>
  )
}

export default Contact;